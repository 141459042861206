import clsx from "clsx";
import type { ReactNode } from "react";
import { useMemo, useState } from "react";
import { useFetcher } from "react-router";
import Badge from "~/components/badge";
import {
  IconCheckedCircle,
  IconCircle,
  IconCircleRight,
  IconEdit,
  IconLoading,
  IconTrash
} from "~/components/icons";
import Link from "~/components/link";
import {
  canAccessProject,
  lockedProjectWarning
} from "~/components/projects/team";
import Cell from "~/components/table-cell";
import Row from "~/components/table-row";
import TaskForm from "~/components/tasks/form";
import type { TaskFieldsFragment } from "~/types/api";
import { useCurrentUser } from "~/utils/auth";
import { formatDate, isPastDue } from "~/utils/dates";
import { formatMoney, formatNumber } from "~/utils/formatting";
import { parentName } from "~/utils/tasks";

interface Props {
  task: TaskFieldsFragment;
  rowNumber: number;
  small?: boolean;
  filterSensitive?: boolean;
  includeBalanceDue?: boolean;
  includeParent?: boolean;
  onSelect?: (task: TaskFieldsFragment) => void;
  selected?: boolean;
}

export default function TaskRow({
  task,
  rowNumber,
  small,
  includeParent,
  includeBalanceDue,
  onSelect,
  selected,
  filterSensitive
}: Props) {
  const currentUser = useCurrentUser();
  const canView = canAccessProject(currentUser, task);
  const [editing, setEditing] = useState(false);
  const [completing, setCompleting] = useState(false);
  const fetcherComplete = useFetcher<unknown>();
  const fetcherDelete = useFetcher<unknown>();

  const destroy = () => {
    if (window.confirm("Are you sure you want to delete this task?")) {
      fetcherDelete.submit(
        { id: task.id },
        { action: "/resources/tasks/delete", method: "post" }
      );
    }
  };

  const complete = () => {
    setCompleting(true);
    fetcherComplete.submit(
      {
        id: task.id,
        completed: task.completed ? "0" : "1",
        completerId: currentUser.id
      },
      { action: "/resources/tasks/save", method: "post" }
    );
  };

  const pastDue = !task.completed && isPastDue(task.itemDate);

  const rowClass = task.completed
    ? "text-muted"
    : pastDue
      ? "danger"
      : undefined;

  const description = useMemo(() => {
    let kanban: ReactNode;
    const { kanbanCard } = task;
    if (!canView) {
      return lockedProjectWarning;
    }
    if (kanbanCard) {
      if (kanbanCard.title && kanbanCard.deliverable) {
        kanban = (
          <strong>
            <Badge mode="info" label="Kanban" />{" "}
            {kanbanCard.deliverable.description} — {kanbanCard.title}:{" "}
          </strong>
        );
      } else if (kanbanCard.title) {
        kanban = (
          <strong>
            <Badge mode="info" label="Kanban" /> {kanbanCard.title}:{" "}
          </strong>
        );
      } else {
        kanban = (
          <strong>
            <Badge mode="info" label="Kanban" />{" "}
            {kanbanCard.deliverable!.description}:{" "}
          </strong>
        );
      }
    }
    const category = task.category ? (
      <span>
        <strong className="text-info">{task.category}</strong> •{" "}
      </span>
    ) : null;
    return (
      <span>
        {task.repeat !== "No" && (
          <div className="float-right ml-2">
            <Badge label={`Repeats ${task.repeatInterval!}`} />
          </div>
        )}
        {category}
        {kanban}
        {task.deliverable && <strong>{task.deliverable.description} </strong>}
        {task.description}
        {task.audit && (
          <div>
            <em>From {formatDate(task.audit.itemDate)} Audit</em>
          </div>
        )}
      </span>
    );
  }, [task, canView]);

  const actions = (
    <Cell size="80" className="text-right print:!hidden">
      {(filterSensitive && task.employee && includeParent) ||
      !canView ? null : (
        <>
          <Link to={() => setEditing(true)}>
            <IconEdit fixed />
          </Link>
          <Link to={destroy}>
            <IconTrash fixed />
          </Link>
        </>
      )}
      {editing && <TaskForm id={task.id} onClose={() => setEditing(false)} />}
    </Cell>
  );

  const date = useMemo(() => {
    const date = task.itemDate ? formatDate(task.itemDate) : "-";
    if (pastDue) {
      return <span className="text-danger font-bold">{date}</span>;
    }
    return date;
  }, [pastDue, task.itemDate]);

  const user = useMemo(() => {
    let user: ReactNode = task.user?.fullname || "N/A";
    if (task.user && task.creator && task.creator.id !== task.user.id) {
      user = (
        <span>
          {task.creator.fullname} <IconCircleRight /> {task.user.fullname}
        </span>
      );
    }

    return user;
  }, [task.creator, task.user]);

  const renderSmall = useMemo(
    () => (
      <>
        <Cell size="80" className="text-center">
          {date}
        </Cell>
        <Cell>
          <span>
            <strong>{task.priority}</strong> •{" "}
          </span>
          <strong>{user}</strong> • {description}
        </Cell>
      </>
    ),
    [date, description, task.priority, user]
  );

  const renderFull = useMemo(
    () => (
      <>
        {includeParent && (
          <Cell>
            {filterSensitive && task.employee
              ? "Private Action"
              : parentName(task, true)}
          </Cell>
        )}
        <Cell style={{ wordBreak: "break-word" }}>
          {filterSensitive && task.employee && includeParent
            ? "Private Action"
            : description}
        </Cell>
        <Cell size="140" className="text-center print:!hidden">
          {user}
        </Cell>
        <Cell size="100" className="text-center print:!hidden">
          {date}
        </Cell>
        <Cell size="80" className="text-center print:!hidden">
          {task.priority}
        </Cell>
        <Cell size="80" className="text-center print:!hidden">
          {formatNumber(task.hours, { format: "0.0" })}
        </Cell>
        {includeBalanceDue && task.category === "A/R" && (
          <Cell size="100" className="text-right print:!hidden">
            {formatMoney(task.project?.totalDue)}
          </Cell>
        )}
      </>
    ),
    [
      date,
      description,
      includeParent,
      includeBalanceDue,
      task,
      user,
      filterSensitive
    ]
  );

  return (
    <>
      <Row className={rowClass}>
        {!small && (
          <Cell size="40" className="text-center">
            {rowNumber}
          </Cell>
        )}
        {!onSelect && (
          <Cell
            size="30"
            className="text-center"
            style={{ cursor: "pointer" }}
            onClick={complete}
          >
            {completing ? (
              <IconLoading large />
            ) : task.completed ? (
              <IconCheckedCircle large />
            ) : (
              <IconCircle large />
            )}
          </Cell>
        )}

        {small ? renderSmall : renderFull}
        {onSelect && (
          <Cell size="30" className="text-center">
            <input
              type="checkbox"
              checked={selected}
              onChange={() => onSelect(task)}
            />
          </Cell>
        )}
        {!onSelect && actions}
      </Row>
      {!small && (
        <Row className={clsx(rowClass, "hidden print:!flex")}>
          <Cell className="!border-t-0" size="70" />
          <Cell className="!border-t-0">{user}</Cell>
          <Cell size="100" className="!border-t-0 text-center">
            {date}
          </Cell>
          <Cell size="80" className="!border-t-0 text-center">
            {task.priority}
          </Cell>
          <Cell size="80" className="!border-t-0 text-center">
            {formatNumber(task.hours, { format: "0.0" })}
          </Cell>
        </Row>
      )}
    </>
  );
}
