import { useRef, useState } from "react";
import FormattedDate from "~/components/formatted-date";
import type { ModalProps } from "~/components/modal";
import Modal from "~/components/modal";
import { RemixForm } from "~/components/remix-form";
import { Buttons } from "~/components/remix-form/buttons";
import { Errors } from "~/components/remix-form/errors";
import { Input } from "~/components/remix-form/input";
import { PRIORITIES } from "~/constants";
import type { TaskFieldsFragment, TaskInput } from "~/types/api";

interface Props extends Omit<ModalProps, "form"> {
  showCategory?: boolean;
  global?: boolean;
  data: TaskFieldsFragment | TaskInput;
  parentType?: string;
  parentId?: string;
}

export const TASK_CATEGORIES = [
  "Call",
  "E-mail",
  "Follow-up",
  "Lunch",
  "Meeting",
  "Thank-you"
];

const REPEAT_OPTIONS = ["No", "From Due Date", "From Completion Date"];
const REPEAT_INTERVAL_OPTIONS = ["Daily", "Weekly", "Monthly", "Annually"];

export default function ModalForm({
  data,
  showCategory,
  onClose,
  global,
  parentType,
  parentId
}: Props) {
  const ref = useRef(null);
  const [projectId, setProjectId] = useState(data.projectId);
  const [category, setCategory] = useState(data.category);
  const [repeat, setRepeat] = useState(data.repeat);
  const [repeatInterval, setRepeatInterval] = useState(
    data.repeatInterval || ""
  );

  const deliverableField =
    projectId && category !== "A/R" ? (
      <Input name="deliverableId" type="deliverable" projectId={projectId} />
    ) : (
      <input type="hidden" name="deliverableId" value="" />
    );

  return (
    <Modal onExplicitClose={onClose} initialFocus={ref}>
      <RemixForm
        data={data}
        fetcher
        action="/resources/tasks/save"
        onSuccess={onClose}
      >
        {!("__typename" in data) && (
          <>
            <input
              type="hidden"
              name="creatorId"
              value={data.creatorId || undefined}
            />
            {!global && (
              <input
                type="hidden"
                name="projectId"
                value={data.projectId || undefined}
              />
            )}
            {parentType && parentId && (
              <input type="hidden" name={`${parentType}Id`} value={parentId} />
            )}
          </>
        )}
        <Modal.Header title={`${data.id ? "Edit" : "New"} Action`} />
        <Modal.Body>
          <Errors />
          {global && (
            <Input
              name="projectId"
              type="project"
              value={projectId}
              onChange={(_name, value) => setProjectId(value)}
              mode="Both"
              label="Project or Campaign"
            />
          )}

          {deliverableField}
          <Input
            name="description"
            type="textarea"
            minRows={2}
            forwardRef={ref}
          />
          <div className="grid grid-cols-2 gap-8">
            <Input
              name="userId"
              type="user"
              label="Who"
              placeholder="Who?"
              project={projectId ? { id: projectId } : undefined}
            />
            <Input name="itemDate" type="date" label="Due Date" />
          </div>
          <div className="grid grid-cols-2 gap-8">
            <Input
              name="priority"
              type="combo"
              options={PRIORITIES}
              isClearable={false}
            />
            <Input name="hours" />
          </div>

          {showCategory ? (
            <div className="grid grid-cols-2 gap-8">
              <Input
                name="category"
                value={category}
                onChange={(_name, value) => setCategory(value)}
                type="combo"
                options={TASK_CATEGORIES}
                placeholder="No Category"
              />
            </div>
          ) : (
            <input
              type="hidden"
              name="category"
              value={category || undefined}
            />
          )}
          <div className="checkbox">
            <label className="font-bold">
              <input
                type="checkbox"
                checked={repeat !== "No"}
                onChange={() => {
                  if (repeat === "No") {
                    setRepeat("From Due Date");
                    setRepeatInterval(repeatInterval || "Weekly");
                  } else {
                    setRepeat("No");
                  }
                }}
              />{" "}
              Repeat
            </label>
          </div>
          {repeat === "No" ? (
            <input type="hidden" name="repeat" value="No" />
          ) : (
            <div className="grid grid-cols-2 gap-8">
              <Input
                name="repeatInterval"
                value={repeatInterval}
                onChange={(_name, value) => setRepeatInterval(value)}
                type="combo"
                options={REPEAT_INTERVAL_OPTIONS}
                isClearable={false}
                noLabel
              />
              <Input
                name="repeat"
                type="combo"
                value={repeat}
                onChange={(_name, value) => setRepeat(value)}
                options={REPEAT_OPTIONS}
                isClearable={false}
                noLabel
              />
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Buttons modal>
            {"__typename" in data && (
              <em className="text-gray-500">
                Added <FormattedDate date={data.createdAt} /> by{" "}
                {data.creator.fullname}
              </em>
            )}
          </Buttons>
        </Modal.Footer>
      </RemixForm>
    </Modal>
  );
}
